/* You can add global styles to this file, and also import other style files */

 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
  position: fixed;
  background-color: rgba(114, 119, 122, 0.1);
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

.sidebar-link {
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 7px;
  color: black;
  text-decoration: none;
}

.sidebar-link:hover {
  background-color: white;
  cursor: pointer;
  color: orange;
  transition-duration: 0.2s;
}

.contenedor-link{
  padding-right: 5px;
  padding-left: 5px;
}

.link-dashboard:hover{
  color: #2196f3;
}

.link-mensajes:hover{
  color:#795548;
}

.link-pasajeros:hover{
  color:#ff5722;
}

.link-comentarios:hover{
  color:#9c27b0;
}

.link-nuevo:hover{
  color:#e91e63;
}

.link-calendario:hover{
  color:#7A6635;
}

.link-empresa:hover{
  color:#137C0F;
}

.link-plan:hover{
  color:#1640A2;
}


#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

.menu-superior{
  min-height: 66px; 
  background-color: rgba(114, 119, 122, 0.1);
  border-left: 1px solid #dee2e6;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }


.color_transition_main{
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
}

.color_transition_container{
    -webkit-transition: background-color 498ms linear;
    -ms-transition: background-color 498ms linear;
    transition: background-color 498ms linear;
}


}

html, body { height: 100%; }
body { margin: 0;}

html, body { height: 100%; }
/*
material angular style
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/